<template>
	<svg width="597" height="800" viewBox="0 280 597 600" fill="none" xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink">
		<g id="practitioner mobile">
			<top-paths></top-paths>
			<bee-hive-game></bee-hive-game>
			<be-healthy-for-life></be-healthy-for-life>
			<bhfl></bhfl>
			<bhu></bhu>
			<mid-path1></mid-path1>
			<certifications></certifications>
			<billing></billing>
			<mid-path2></mid-path2>
			<calendar></calendar>
			<mid-path3></mid-path3>
			<community-resources></community-resources>
			<mid-path4></mid-path4>
			<creds-insurance></creds-insurance>
			<contact-practitioner></contact-practitioner>
			<mid-path5></mid-path5>
			<dsm-asam></dsm-asam>
			<ehr></ehr>
			<emergency></emergency>
			<mid-path6></mid-path6>
			<events></events>
			<forms></forms>
			<mid-path7></mid-path7>
			<grants></grants>
			<mid-path8></mid-path8>
			<cpt-codes></cpt-codes>
			<messenger></messenger>
			<my-contact></my-contact>
			<mid-path9></mid-path9>
			<online-store></online-store>
			<self-care></self-care>
			<practice-manager></practice-manager>
			<mid-path10></mid-path10>
			<supervision></supervision>
			<social-media></social-media>
			<services></services>
			<mid-path11></mid-path11>
			<wellness-centers></wellness-centers>
			<treatment-planning></treatment-planning>
			<mid-path12></mid-path12>
			<patient-portal></patient-portal>
			<bottom-paths></bottom-paths>
		</g>
		<definitions></definitions>
	</svg>
</template>

<script>
import TopPaths from './ProMenuSmallItems/TopPaths.vue';
import BeeHiveGame from './ProMenuSmallItems/BeeHiveGame.vue';
import BeHealthyForLife from './ProMenuSmallItems/BeHealthyForLife.vue';
// add link in the bottom one
import Bhfl from './ProMenuSmallItems/Bhfl.vue';
import Bhu from './ProMenuSmallItems/Bhu.vue';
import MidPath1 from './ProMenuSmallItems/MidPath1.vue';
import Certifications from './ProMenuSmallItems/Certifications.vue';
import Billing from './ProMenuSmallItems/Billing.vue';
import MidPath2 from './ProMenuSmallItems/MidPath2.vue';
import Calendar from './ProMenuSmallItems/Calendar.vue';
import MidPath3 from './ProMenuSmallItems/MidPath3.vue';
import CommunityResources from './ProMenuSmallItems/CommunityResources.vue';
import MidPath4 from './ProMenuSmallItems/MidPath4.vue';
import CredsInsurance from './ProMenuSmallItems/CredsInsurance.vue';
import ContactPractitioner from './ProMenuSmallItems/ContactPractitioner.vue';
import MidPath5 from './ProMenuSmallItems/MidPath5.vue';
import DsmAsam from './ProMenuSmallItems/DsmAsam.vue';
import Ehr from './ProMenuSmallItems/Ehr.vue';
import Emergency from './ProMenuSmallItems/Emergency.vue';
import MidPath6 from './ProMenuSmallItems/MidPath6.vue';
import Events from './ProMenuSmallItems/Events.vue';
import Forms from './ProMenuSmallItems/Forms.vue';
import MidPath7 from './ProMenuSmallItems/MidPath7.vue';
import Grants from './ProMenuSmallItems/Grants.vue';
import MidPath8 from './ProMenuSmallItems/MidPath8.vue';
import CptCodes from './ProMenuSmallItems/CptCodes.vue';
import Messenger from './ProMenuSmallItems/Messenger.vue';
import MyContact from './ProMenuSmallItems/MyContact.vue';
import MidPath9 from './ProMenuSmallItems/MidPath9.vue';
import OnlineStore from './ProMenuSmallItems/OnlineStore.vue';
import SelfCare from './ProMenuSmallItems/SelfCare.vue';
import PracticeManager from './ProMenuSmallItems/PracticeManager.vue';
import MidPath10 from './ProMenuSmallItems/MidPath10.vue';
import Supervision from './ProMenuSmallItems/Supervision.vue';
import SocialMedia from './ProMenuSmallItems/SocialMedia.vue';
import Services from './ProMenuSmallItems/Services.vue';
import MidPath11 from './ProMenuSmallItems/MidPath11.vue';
import WellnessCenters from './ProMenuSmallItems/WellnessCenters.vue';
import TreatmentPlanning from './ProMenuSmallItems/TreatmentPlanning.vue';
import MidPath12 from './ProMenuSmallItems/MidPath12.vue';
import PatientPortal from './ProMenuSmallItems/PatientPortal.vue';
import BottomPaths from './ProMenuSmallItems/BottomPaths.vue';
import Definitions from './ProMenuSmallItems/Definitions.vue';

export default {
	components: {
		TopPaths,
		BeeHiveGame,
		BeHealthyForLife,
		Bhfl,
		Bhu,
		MidPath1,
		Certifications,
		Billing,
		MidPath2,
		Calendar,
		MidPath3,
		CommunityResources,
		MidPath4,
		CredsInsurance,
		ContactPractitioner,
		MidPath5,
		DsmAsam,
		Ehr,
		Emergency,
		MidPath6,
		Events,
		Forms,
		MidPath7,
		Grants,
		MidPath8,
		CptCodes,
		Messenger,
		MyContact,
		OnlineStore,
		SelfCare,
		PracticeManager,
		MidPath10,
		Supervision,
		SocialMedia,
		Services,
		MidPath11,
		WellnessCenters,
		TreatmentPlanning,
		MidPath12,
		PatientPortal,
		BottomPaths,
		Definitions,
		MidPath9,
	},
	setup() {
		return {};
	},
};
</script>